@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Nunito:wght@400;500;800;900&family=Poppins:wght@100;200;300;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100&display=swap");

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke-width: 0.5;
  background-color: #ffffff;
  height: 100vh;
}
/* #root,.App{
  height: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invite-user-modal {
  max-width: 56rem !important;
}
.invite-user-content-modal,
.api-add-content-modal {
  border-radius: 0.625rem;
}

/* body::-webkit-scrollbar { */
/* width: 0.5rem;           
}

body::-webkit-scrollbar-track {
  background: transparent;   
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(101, 90, 236); 
  padding-right: 0.4rem;
  border: 3px solid transparent; */
/* } */

/* @keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
} */
/* @keyframes scale {
  to {
    transform: scale(1.5);
  }
}
@keyframes card{
  to{
    transform:scale(1.1);
  }
} */
.api-add-dialog-modal {
  max-width: 55rem !important;
  /* animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards; */
}
.instrctions-dialog-modal {
  max-width: 64rem !important;
}
.invite-user-modal {
  display: flex;
  justify-content: center;
}
@media (max-width: 59rem) {
  .invite-user-modal {
    max-width: 40rem !important;
  }
  .api-add-dialog-modal {
    max-width: 40rem !important;
  }
  .instrctions-dialog-modal {
    max-width: 50rem !important;
  }
}
#root {
  background: rgb(248, 247, 251);
}

.workflow-add-modal {
  max-width: unset !important;
  /* width:95%; */
}

.popover-div {
  color: #000;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.3125rem 0.625rem;
  min-width: 7.65rem;
  border-radius: 0.3125rem;
  background: #fff;
  border: 0.0625rem solid #eaecf0;
}

.spinner {
  animation: spinner 1s linear infinite;
  height: 3.5rem;
  vertical-align: unset;
  width: 3.5rem;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
