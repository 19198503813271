.dndflow {
  flex-direction: row;
  display: flex;
  height: 100%;
}
.dndflow is-selected {
  flex-direction: row;
  display: flex;
  height: 100%;
  width: 58vw;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 0.9375rem 0.625rem;
  font-size: 0.75rem;
  background: #fcfcfc;
  width: 48.509vw;
}

.dndflow aside > * {
  margin-bottom: 0.625rem;
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 0.625rem;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 48.509vw;
  }
}
.top-node {
  display: flex;
  align-items: center;
}
.NodeWrapper {
  width: 21rem;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px #d0d5dd solid;
  padding: 1rem 0px 1rem 1.25rem;
  border-radius: 0.5rem;
  transition: transform 0.4s cubic-bezier(0.35, 0, 0.25, 1);

  &:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }
}
.transition {
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1); /* Adjust the duration and easing as needed */
}

.is-active-node {
  background: #ffffff;
  border-radius: 9px;
  border-style: solid;
  border-color: #655aec;
  transition: all 0.15s linear;
  border-width: 3px 0.8px 0.8px 0.8px;
}

.empty-node {
  width: 21rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
}

.react-flow__handle {
  opacity: 0;
}
